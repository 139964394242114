'use client'

import { toLangCode } from '@/components/i18n/lang-context'
import { redirect, usePathname } from 'next/navigation'

export default function NotFound() {

  const pathName = usePathname()

  function redirectTo404() {
    const pathNames = pathName.split('/')
    let langCode = toLangCode(pathNames.length < 2 ? '' : pathNames[1])
    let redirectPath = '/'+ langCode + '/404?from='+pathName
    redirect(redirectPath)
  }

  return (<>{redirectTo404()}</>)
}
